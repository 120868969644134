@import "styles/variables";

$MAIN_REVIEW_MOBILE_MAX_WIDTH: 34.5rem;
$QUOTE_IMAGE_MOBILE_BOTTOM: 2.4rem;
$QUOTE_IMAGE_DESKTOP_BOTTOM: 3.1rem;

.mainReviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $MAIN_REVIEW_MOBILE_MAX_WIDTH;
  height: 44.8rem;
  margin: 2rem 0 5.5rem;
  margin-bottom: $QUOTE_IMAGE_MOBILE_BOTTOM;
  padding: 5.5rem 1.5rem 2.78rem;
  background-color: $WHITE;
  box-sizing: border-box;
  box-shadow: 0 0.3rem 0.6rem 0 rgb(0 0 0 / 16%);
  position: relative;

  @include tablet-landscape {
    width: 30.4rem;
    margin: 2rem 2.4rem $QUOTE_IMAGE_DESKTOP_BOTTOM;
  }

  @include desktop {
    width: 40.8rem;
    margin: 2rem 2.8rem $QUOTE_IMAGE_DESKTOP_BOTTOM;
    min-height: 39rem;
  }

  .reviewText {
    width: 23.1rem;
    margin-top: 3rem;
    color: $GUNMETAL;
    font-size: 1.5rem;
    font-style: italic;
    text-align: center;
  }

  .reviewAuthor {
    margin-top: 5rem;
    color: $GUNMETAL;
    font-size: 1.5rem;
  }

  .reviewAgency {
    margin-top: 0.2rem;
    margin-bottom: 3.9rem;
    color: #199fff;
    font-size: 1.5rem;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
  }

  .avisVerifiesImageWrapper {
    width: 8.96rem;
    height: 2.8rem;
    margin: 0 auto;
    position: absolute;
    bottom: 2.8rem;
    left: 50%;
    transform: translateX(-50%);

    @include desktop {
      bottom: 2.8rem;
    }
  }

  .quoteImageContainer {
    position: absolute;
    bottom: -$QUOTE_IMAGE_MOBILE_BOTTOM;
    right: 2rem;

    @include desktop {
      bottom: -$QUOTE_IMAGE_DESKTOP_BOTTOM;
    }

    .quoteImageWrapper {
      width: 8.1rem;
      height: 7.1rem;
      position: relative;

      @include desktop {
        width: 10.2rem;
        height: 9.3rem;
      }
    }
  }
}
