@import "styles/variables";
@import "mainReviewBlock/MainReviewBlock.module.scss";

.reviewsSectionColor {
  background-color: $WHITE-GREY;
}

.reviewsBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  background-color: $WHITE-GREY;

  @include tablet-landscape {
    padding: 4rem 0;
  }

  @include desktop {
    padding: 4rem 0;
  }

  .reviewsBlockTitle {
    margin-bottom: 2.5rem;
    color: $MARINE;
    font-size: 2.8rem;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
  }

  .mobileNavigationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $MAIN_REVIEW_MOBILE_MAX_WIDTH;

    @include tablet-landscape {
      justify-content: center;
    }

    .reviewsStatsContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 17.08rem;
      height: 7rem;
      padding: 0.59rem 0.55rem 0.49rem 0.56rem;
      background-color: $WHITE;
      min-width: 16rem;
      justify-items: center;
      gap: 0.88rem;

      .ratingContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .logoWrapper {
          width: 7.35rem;
          height: 2.25rem;
        }

        .averageRating {
          color: #0073ff;
          font-size: 1.8rem;
          font-weight: bolder;
        }
      }

      .starContainer {
        display: flex;
        justify-content: center;
        width: 15.97rem;
      }
    }
  }

  .reviewsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @include tablet-landscape {
      flex-direction: row;
    }

    .rightReviewContainer,
    .leftReviewContainer {
      width: 25%;
      max-width: 29.9rem;
    }

    .leftReviewContainer {
      @include tablet-landscape {
        margin-right: 2.8rem;
      }

      @include desktop {
        margin-right: 0;
      }
    }
  }

  $DESKTOP_ARROW_ICONS_MARGIN: 7.7rem;
  $TABLET_ARROW_ICONS_MARGIN: 2.4rem;

  .rightArrowContainer {
    background-color: transparent;

    @include tablet-landscape {
      margin-left: $TABLET_ARROW_ICONS_MARGIN;
    }

    @include desktop {
      margin-left: $DESKTOP_ARROW_ICONS_MARGIN;
    }
  }

  .leftArrowContainer {
    background-color: transparent;
    transform: rotate(180deg);

    @include tablet-landscape {
      margin-right: $TABLET_ARROW_ICONS_MARGIN;
    }

    @include desktop {
      margin-right: $DESKTOP_ARROW_ICONS_MARGIN;
    }
  }

  .flexRow {
    display: flex;
  }
}
