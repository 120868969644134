@import "styles/variables";
@import "../mainReviewBlock/MainReviewBlock.module.scss";

.legalText {
  margin-top: 3.5rem;
  color: $GUNMETAL;
  font-size: 1rem;
  text-align: justify;
  max-width: $MAIN_REVIEW_MOBILE_MAX_WIDTH;

  @include tablet-landscape {
    margin-top: 2rem;
    position: absolute;
    bottom: $QUOTE_IMAGE_DESKTOP_BOTTOM;
  }

  @include desktop {
    margin-top: 3.5rem;
  }

  .boldLegalText {
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
  }

  .link {
    color: $MARINE;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
    text-decoration: underline;
  }
}
