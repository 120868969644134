@import "styles/variables";

$MAIN_REVIEW_MOBILE_MAX_WIDTH: 34.5rem;
$QUOTE_IMAGE_MOBILE_BOTTOM: 2.1rem;
$QUOTE_IMAGE_DESKTOP_BOTTOM: 3.1rem;

.mainReviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $MAIN_REVIEW_MOBILE_MAX_WIDTH;
  margin-top: 2rem;
  margin-bottom: $QUOTE_IMAGE_MOBILE_BOTTOM;
  padding: 5.2rem 5rem 1.5rem;
  background-color: $WHITE;
  box-sizing: border-box;
  box-shadow: 0 0.3rem 0.6rem 0 rgb(0 0 0 / 16%);
  position: relative;
  min-height: 41rem;

  @include tablet-landscape {
    width: 30.4rem;
    margin: 2rem 2.8rem $QUOTE_IMAGE_DESKTOP_BOTTOM;
    min-height: 42rem;
  }

  @include desktop {
    width: 40.8rem;
    min-height: 39rem;
  }

  .reviewText {
    margin-top: 3rem;
    color: $GUNMETAL;
    font-size: 1.5rem;
    font-style: italic;
    text-align: center;
  }

  .reviewAuthor {
    margin-top: 5rem;
    color: $GUNMETAL;
    font-size: 1.5rem;
  }

  .reviewAgency {
    margin-top: 0.2rem;
    margin-bottom: 3.9rem;
    color: $LIPSTICK;
    font-size: 1.5rem;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
  }

  .avisVerifiesImageWrapper {
    width: 11.1rem;
    height: 3rem;
    position: relative;

    @include tablet-landscape {
      width: 7.5rem;
      height: 2rem;
    }

    @include desktop {
      width: 11.1rem;
      height: 3rem;
    }
  }

  .quoteImageContainer {
    position: absolute;
    bottom: -$QUOTE_IMAGE_MOBILE_BOTTOM;
    right: 2rem;

    @include desktop {
      bottom: -$QUOTE_IMAGE_DESKTOP_BOTTOM;
    }

    .quoteImageWrapper {
      width: 8.1rem;
      height: 7.1rem;
      position: relative;

      @include desktop {
        width: 10.2rem;
        height: 9.3rem;
      }
    }
  }
}
