@import "styles/variables";
@import "mainReviewBlock/MainReviewBlock.module.scss";

.reviewsSectionColor {
  background-color: $WHITE-GREY;
}

.reviewsBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  background-color: $WHITE-GREY;

  @include tablet-landscape {
    padding: 4rem 0;
  }

  @include desktop {
    padding: 4rem 0;
  }

  .reviewsBlockTitle {
    margin-bottom: 1.5rem;
    color: $MARINE;
    font-size: 2.8rem;
    font-weight: $FONT-WEIGHT-SEMI-BOLD;
  }

  .mobileNavigationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $MAIN_REVIEW_MOBILE_MAX_WIDTH;

    @include tablet-landscape {
      justify-content: center;
    }

    .reviewsStatsContainer {
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
      background-color: $WHITE;

      .ratingsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 2rem;

        .averageRating {
          color: $GUNMETAL;
          font-size: 2.5rem;
        }
      }

      .reviewsNumberContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .reviewsNumber {
          color: $GUNMETAL;
        }
      }
    }
  }

  .reviewsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @include tablet-landscape {
      flex-direction: row;
    }

    .rightReviewContainer,
    .leftReviewContainer {
      width: 25%;
      max-width: 29.9rem;
    }
  }

  $DESKTOP_ARROW_ICONS_MARGIN: 7.7rem;
  $TABLET_ARROW_ICONS_MARGIN: 2.4rem;

  .rightArrowContainer {
    background-color: transparent;

    @include tablet-landscape {
      margin-left: $TABLET_ARROW_ICONS_MARGIN;
    }

    @include desktop {
      margin-left: $DESKTOP_ARROW_ICONS_MARGIN;
    }
  }

  .leftArrowContainer {
    background-color: transparent;
    transform: rotate(180deg);

    @include tablet-landscape {
      margin-right: $TABLET_ARROW_ICONS_MARGIN;
    }

    @include desktop {
      margin-right: $DESKTOP_ARROW_ICONS_MARGIN;
    }
  }

  .flexRow {
    display: flex;
  }
}
