@import "styles/variables";

.smallReviewBlock {
  width: 29.9rem;
  padding: 3rem 3.7rem;
  background-color: $WHITE;
  opacity: 0.6;
  box-shadow: 0 0.3rem 0.6rem 0 rgb(0 0 0 / 16%);
  box-sizing: border-box;

  .reviewText {
    color: $BLUEY-GREY;
    font-size: 1rem;
    font-style: italic;
    text-align: center;
  }

  .reviewDetailsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;

    .reviewAuthor {
      color: $BLUEY-GREY;
      font-size: 1.1rem;
      font-style: italic;
    }

    .reviewAgency {
      margin-left: 0.6rem;
      font-weight: $FONT-WEIGHT-SEMI-BOLD;
      font-style: normal;
    }
  }
}
